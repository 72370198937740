@charset "UTF-8";
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
body {
  background-color: #fdf9ed;
  font-family: "Montserrat", sans-serif;
  color: #272727;
}

h1 {
  color: #272727;
}

a {
  text-decoration: none;
  color: #754dea;
}

a:hover {
  color: #272727;
  text-decoration: none;
}

a > i {
  font-size: xx-large;
}

.btn-primary {
  background-color: #754dea;
}

.btn-primary:hover {
  background-color: #272727;
}

.card {
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 0.5em;
}

.card:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22);
}

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 1.2em;
  height: 1.2em;
  border-radius: 0.15em;
  border: 0.1em solid #272727;
  outline: none;
  cursor: pointer;
}

.checkbox:checked {
  background-color: #754dea;
  position: relative;
}

.checkbox:checked::before {
  content: "✓";
  font-size: 1.1em;
  color: #fdf9ed;
  position: absolute;
  right: 1px;
  top: -5px;
}

.btn-transparent-bg {
  float: right;
  font-size: inherit;
  color: #754dea;
  padding: 0;
  min-width: 1em;
}

